import React from "react";
import Img from "gatsby-image";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { slice, split } from "ramda";
import { rhythm } from "utils/typography";
import Layout from "components/layout";
import { mq } from "../utils/helper";
import SEO from "components/seo";
import { colors } from "style/theme";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "components/mdxLink";

const designSystem = {
  a: styled(MdxLink)({
    color: colors.primary,
    textDecoration: "underline"
  }),
  p: styled.p({
    color: colors.light,
    fontSize: "21px",
    marginRight: 0,
    marginBottom: "1.5rem",
  }),
  li: styled.li({
    color: colors.light,
    fontSize: "21px",
    marginLeft: "1.2rem",
  }),
  h2: styled.h2({
    color: colors.light,
    marginTop: "4rem",
    "&:first-child": {
      marginTop: "0"
    }
  }),
}

const H2 = designSystem.h2;

// every image's name contains a link
const imageLink = img =>
  split("-", slice(0, -4, img.childImageSharp.fluid.originalName))[1];

function ListImages({ images, title }) {
  return (
    <div
      css={{
        maxWidth: "300px",
        [mq[1]]: {
          margin: "auto"
        }
      }}
    >
      <H2>{title}</H2>
      <div>
        {images.map(image => (
          <a href={`https://${imageLink(image)}`}>
            <Img
              css={{
                filter: "grayscale(100%)",
                transition: "0.35s",
                marginBottom: "1.4rem",
                width: "55%",
                height: "70%",
                ": hover": {
                  cursor: "pointer",
                  opacity: 1,
                  filter: "grayscale(0)"
                },
                [mq[1]]: {
                  width: "60%"
                }
              }}
              fluid={image.childImageSharp.fluid}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

function About({ data }) {
  const partnersImages = data.partnersImages.edges.map(p => p.node);
  const fundersImages = data.fundersImages.edges.map(p => p.node);

  return (
    <div
      css={{
        backgroundColor: colors.dark,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SEO title="About" />
      <img
        src="/assets/about-background.gif"
        css={{
          position: "absolute",
          top: 0,
          left: "30%",
          width: "60%",
          height: "auto",
          zIndex: 0,
          opacity: 0.2
        }}
      />
      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            position: "relative",
            zIndex: 2,
            padding: `0 ${rhythm(1.3)}`
          }}
        >
          <h2
            css={{
              color: colors.primary,
              margin: 0,
              fontSize: "48px",
              display: "block",
            }}
          >
            {data.pageContent.frontmatter.title}
          </h2>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "3fr 1fr",
              gridColumnGap: rhythm(5),
              marginTop: "4rem",
              [mq[1]]: {
                display: "block",
              }
            }}
          >
            <div>
              <MDXProvider
                components={{
                  a: designSystem.a,
                  p: designSystem.p,
                  li: designSystem.li,
                  h2: designSystem.h2,
                }}
              >
                <MDXRenderer>{data.pageContent.body}</MDXRenderer>
              </MDXProvider>
            </div>
            <div>
              <ListImages images={partnersImages} title="Our Partners" />
              <div
                css={{
                  borderBottom: `6px solid ${colors.light}`,
                  width: "70%",
                  margin: `2rem 0`
                }}
              ></div>
              <ListImages images={fundersImages} title="Our Funders" />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default About;

export const pageQuery = graphql`
  query aboutPage {
    pageContent: mdx(fileAbsolutePath: {regex: "/content/about/"}, slug: {eq: "about/index.en"}) {
      id
      slug
      body
      frontmatter {
        title
      }
    }
    partnersImages: allFile(filter: { name: { regex: "/partner/" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    fundersImages: allFile(filter: { name: { regex: "/funder/" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
